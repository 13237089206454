import { Component, OnInit, signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { cloneDeep } from 'lodash';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	BaseIsProcessingComponent,
	BooleanTextSelectInputFormFieldComponent,
	ChipMultiSelectTypeaheadInputFormFieldComponent,
	ChipTypeaheadOption,
	DateTimeInputFormFieldComponent,
	ListBuilderComponent,
	ListItem,
	TemperatureInputFormFieldComponent
} from '../shared';
import { IdLabel } from '../generated-models/api/shared';
import { CheckboxGroupInputFormFieldComponent } from '../shared/components/input-form-fields/checkbox-group-input-form-field/checkbox-group-input-form-field.component';
import { MatInputModule } from '@angular/material/input';
import { Observable, of } from 'rxjs';
import { PercentageInputFormFieldComponent } from '../shared/components/input-form-fields/percentage-input-form-field/percentage-input-form-field.component';
import { BiffComponent } from '../biff/biff.component';
import { WeightInputFormFieldComponent } from '../shared/components/input-form-fields/weight-input-form-field/weight-input-form-field.component';
import { SandboxService } from './sandbox.service';
import { SelectTypeaheadOption } from '../shared/components/select-typeahead/select-typeahead.component';

interface option {
	value: string;
	selected?: boolean;
}

interface Question {
	text: string;
	options: option[];
}

@Component({
	standalone: true,
	selector: 'sandbox',
	templateUrl: './sandbox.component.html',
	styleUrl: './sandbox.component.scss',
	imports: [
		MatGridListModule,
		MatDividerModule,
		MatChipsModule,
		MatCardModule,
		MatRadioModule,
		MatButtonModule,
		MatCheckboxModule,
		MatButtonToggleModule,
		MatSliderModule,
		MatInputModule,
		FormsModule,
		CheckboxGroupInputFormFieldComponent,
		ReactiveFormsModule,
		DateTimeInputFormFieldComponent,
		TemperatureInputFormFieldComponent,
		PercentageInputFormFieldComponent,
		ChipMultiSelectTypeaheadInputFormFieldComponent,
		ListBuilderComponent,
		BooleanTextSelectInputFormFieldComponent,
		BiffComponent,
		TemperatureInputFormFieldComponent,
		WeightInputFormFieldComponent
	]
})
export class SandBoxComponent extends BaseIsProcessingComponent implements OnInit {
	public lessConfusingFormControl = new FormControl(null);
	public formControl!: FormControl;

	public formControl2 = new FormControl(null);

	public datetimeFormControl = new FormControl<Date | null>(null);
	public tempFormCtrlF = new FormControl<number | null>(null);
	public tempFormCtrlC = new FormControl<number | null>(null);
	public weightFormCtrlKg = new FormControl<number | null>(null);
	public weightFormCtrlLbs = new FormControl<number | null>(null);

	public options: IdLabel[] = [
		'cats',
		'dogs',
		'checkboxes',
		'other stuff',
		'viral infections',
		'another options',
		'yet another'
	].map((item, index) => ({
		id: index,
		label: item
	}));
	public checkboxColumnCount = signal<number>(1);

	public checkboxColumnCountControl = new FormControl(this.checkboxColumnCount());

	constructor(private _sandboxService: SandboxService) {
		super([_sandboxService.isProcessing$]);

		this.checkboxColumnCountControl.valueChanges.subscribe(value => {
			this.checkboxColumnCount.set(value ?? 1);
		});
	}

	public ngOnInit(): void {
		this.formControl = new FormControl([]);

		this.formControl.valueChanges.subscribe(x => {
			console.log('value changed', x);
		});
	}

	public questions: Question[] = [
		{ text: 'Airway', options: [{ value: 'patent' }, { value: 'compromised' }] },
		{
			text: 'Breathing',
			options: [{ value: 'unlabored' }, { value: 'labored / respiratory distress' }]
		},
		{
			text: 'Criculation',
			options: [{ value: 'normal' }, { value: 'pale / diaphoretic' }]
		},
		{
			text: 'Neuro',
			options: [{ value: 'awake / alert' }, { value: 'lethargic / obtunded' }]
		}
	];

	public highlightedChipQuestions: Question[] = cloneDeep(this.questions);
	public radioQuestions: Question[] = cloneDeep(this.questions);
	public checkboxQuestions: Question[] = cloneDeep(this.questions);
	public buttonGroupQuestions: Question[] = cloneDeep(this.questions);

	public currentPainLevel: number = 1;
	public maxPainLevel: number = 3;
	public acceptablePainLevel: number = 4;

	public optionsFilter(): Observable<ChipTypeaheadOption[]> {
		return of([
			{ key: '1', label: 'Test1' },
			{ key: '2', label: 'Test2' },
			{ key: '3', label: 'Test3' }
		]);
	}

	public optionsFilter2(): Observable<SelectTypeaheadOption[]> {
		return of([
			{ key: '1', label: 'Test1' },
			{ key: '2', label: 'Test2' },
			{ key: '3', label: 'Test3' }
		]);
	}

	public listItemOptionsFilter(value: string): Observable<ListItem[]> {
		const values = [{ label: 'Test1' }, { label: 'Test2' }, { label: 'Test3' }];
		return of(values.filter(f => f.label.toLowerCase().startsWith(value.toLowerCase())));
	}

	public generateDocument() {
		this._sandboxService.generateDocument().subscribe(response => {
			if (response.ok) {
				// const element = document.createElement('a');
				// element.href = URL.createObjectURL(
				// 	new Blob([response.body!], { type: response.headers.get('Content-Type')! })
				// );
				// element.download = response.headers.get('File-Name')!;
				// document.body.appendChild(element);
				// element.click();
				// element.remove();
			}
		});
	}
}
