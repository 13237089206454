import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../shared';
import { Injectable } from '@angular/core';
import { NotificationService } from '../layout';
import { Observable } from 'rxjs';
import {
	RadiologyOrderSearchParameters,
	RadiologyOrderSearchResult
} from '../generated-models/api/admin/radiologyorder';

@Injectable({
	providedIn: 'root'
})
export class SandboxService extends BaseService<void> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('sandbox', _httpClient, _notificationService);
	}

	public generateDocument(): Observable<HttpResponse<Blob>> {
		console.log('hit');
		return this._httpClient.get('generate-document', { observe: 'response', responseType: 'blob' });
	}

	public searchRadiologyOrders(
		searchParameters: RadiologyOrderSearchParameters
	): Observable<HttpResponse<RadiologyOrderSearchResult>> {
		return this.post<RadiologyOrderSearchParameters, RadiologyOrderSearchResult>(
			'search',
			'Failed to search the radiology orders.',
			searchParameters
		);
	}
}
